$text-primary: #3c1211;
$text-secondary: #201a1a;
$icon-secondary: #65312f;
$icon-primary: #fff8f7;
.section-surrounding {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-surrounding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 40px 0;
    @media (max-width: 768px) {
      width: 90%;
      padding: 16px 0;
    }
    .title {
      margin: 0;
      font-size: 3.813rem;
      font-weight: 500;
      font-family: "Inter";
      text-transform: uppercase;
      color: $text-primary;
      @media (max-width: 768px) {
        font-size: 1.813rem;
        text-align: center;
      }
    }
    .container-image {
      width: 100%;
      margin: 40px 0 15px 0;
      .image {
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
      .slick-slider {
        width: 100%;

        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
          color: #56dedd;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
    .container-fasilitas {
      display: flex;
      gap: 20px;
      width: 100%;
      font-family: "Roboto";
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        justify-content: start;
      }
      .point {
        width: fit-content;

        .fasilitas {
          display: flex;
          align-items: center;
          gap: 6px;
          margin-bottom: 15px;
          font-size: 1.2rem;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
          .icon-fasilitas {
            width: 20px;
            height: 20px;
            background-color: $icon-secondary;
            fill: $icon-primary;
            padding: 6px;
            border-radius: 50%;
          }
        }
        .loc {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 1rem;
          font-family: "Roboto";
          color: $text-secondary;
          fill: $text-secondary;
          margin-bottom: 9px;
          @media (max-width: 768px) {
            font-size: 0.8rem;
          }
          .icon-loc {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
