$primary-text: #fff9f9;
$secondary-text: #ffb9b4;
.section-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 90vh;
  z-index: 7;
  &::before {
    content: "";
    background: url("../../Assets/hero\ \(1\).webp");
    background-size: cover; /* Mengatur agar gambar mengisi seluruh area */
    background-position: center;
    /* Menempatkan gambar di tengah */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Meletakkan di belakang elemen lain */
    /* Overlay hitam */
  }
  @media (max-width: 768px) {
    height: 70vh;
  }
  .container-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: fit-content;
    width: 80%;
    text-align: start;

    @media (max-width: 768px) {
      height: fit-content;
      width: 90%;
    }
    .hero-description {
      display: flex;
      flex-direction: column;
      gap: 5px 0;
      background-color: #300808a6;
      padding: 25px;
      border-radius: 25px 25px 0 0;
      text-align: center;
      width: 35%;
      @media (max-width: 768px) {
        width: 70%;
        padding: 15px;
      }
      .hero-title {
        color: $primary-text;
        font-size: 1.8rem;
        font-family: "Inter";
        font-weight: 500;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
      .hero-text {
        color: $secondary-text;
        font-size: 2.8rem;
        font-family: "Inter";
        font-weight: 400;

        margin: 0;
        @media (max-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}
