$text-primary: #ffb9b4;
$text-secondary: #fff9f9;
$btn-primary: #57f785;
$btn-secondary: #002b0e;
$btn-hover: #12cb5f;
$cadet: #609c9b;
.section-promo {
  display: flex;
  justify-content: center;
  text-align: center;
  background: linear-gradient(to right top, #c27f7b, #300808);
  @media (max-width: 768px) {
    align-self: center;
    height: auto;
  }
  .container {
    display: flex;
    width: 80%;
    justify-content: center;
    gap: 0 2rem;
    padding: 40px 0;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 90%;
      align-items: center;
      padding: 16px 0;
    }
    .flyer {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }

      img {
        width: 80%;
        border-radius: 20px;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .highlights {
      width: 50%;
      text-align: start;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      gap: 2rem;
      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        gap: 10px;
      }
      h4 {
        margin: 0;
        color: $text-primary;
        font-size: 3rem;
        font-weight: 500;
        font-family: "Inter";
        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
      }

      .msg-hook {
        display: flex;
        flex-wrap: wrap;
        gap: 4.063rem 2rem;
        @media (max-width: 768px) {
          justify-content: center;
          gap: 0;
        }
        .container-point {
          width: calc(50% - 20px);
          @media (max-width: 768px) {
            width: 100%;
          }
          .point-item {
            display: flex;
            gap: 6px;
            font-size: 1rem;
            margin-bottom: 10px;
            align-items: center;
            @media (max-width: 768px) {
              margin-bottom: 6px;
              gap: 6px;
              font-size: 0.9rem;
            }
            .icon {
              width: 21px;
              height: 21px;
              color: $cadet;
              @media (max-width: 768px) {
              }
            }
            span {
              color: $text-secondary;
              font-family: "Roboto";
            }
          }
        }
        .container-btn {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          @media (max-width: 768px) {
            margin-top: 10px;
            align-items: center;
          }
          .term {
            color: $text-secondary;
            font-family: "Roboto";
            font-size: 0.7rem;
            margin-bottom: 9px;
            opacity: 0.5;
          }
          .btn {
            display: flex;
            font-family: "Roboto";
            font-weight: 500;
            background-color: $btn-primary;
            border: 1px solid $btn-primary;
            border-radius: 9px;
            padding: 9px 25px;
            cursor: pointer;
            color: $btn-secondary;
            transition: 0.5s ease-in-out;
            font-size: 0.9rem;
            align-items: center;
            fill: $btn-secondary;
            &:hover {
              background-color: $btn-hover;
              color: $btn-secondary;
              fill: $btn-secondary;
            }
            .icon {
              margin-right: 6px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
