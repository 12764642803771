$btn-primary: #99d6d4;
$btn-secondary: #001a1a;
$btn-hover: #609c9b;
.section-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 16px 0;
  }
  .container-about {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 90%;
    }
    .caption {
      font-size: 0.9rem;
      color: #201a1a;
      font-family: "Roboto";
    }
    .title {
      margin: 4px 0 9px 0;
      font-family: "Inter";
      font-size: 3.7rem;
      font-weight: 500;
      color: #6c3300;
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }
    .description {
      margin: 0 0 25px 0;
      font-family: "Roboto";
      font-size: 1.1rem;
      color: #201a1a;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 0.9rem;
        text-align: start;
      }
    }
    .container-hook {
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .container-image {
        width: 70%;
        @media (max-width: 768px) {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .container-checkin {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding: 20px;
        background: linear-gradient(to bottom right, #65312f 60%, #427e7d);
        @media (max-width: 768px) {
          margin-top: -5px;
          width: 100%;
          padding: 10px 0;
        }
        .checkin {
          display: flex;
          align-items: center;
          gap: 6px;
          margin-bottom: 9px;
          @media (max-width: 768px) {
            margin-left: 10px;
          }
          span {
            color: #fff9f9;
            font-family: "Roboto";
          }
        }
        .btn-navbar {
          display: flex;
          align-items: center;
          margin-top: 40px;
          font-family: "Roboto";
          font-weight: 500;
          background-color: $btn-primary;
          border: 1px solid $btn-primary;
          border-radius: 9px;
          padding: 9px 25px;
          text-transform: uppercase;
          cursor: pointer;
          color: $btn-secondary;
          transition: 0.5s ease-in-out;
          font-size: 0.9rem;

          @media (max-width: 768px) {
            margin-left: 10px;
            margin-top: 20px;
          }

          &:hover {
            background-color: $btn-hover;
            transition: 0.5s ease-in-out;
            color: $btn-secondary;
          }
          .btn-icon {
            width: 21px;
            height: 21px;
            margin-right: 9px;
            padding: 0;
          }
        }
      }
    }
  }
}
